const binding = { modules: {}, dataActions: {} };

    (binding.modules['fabrikam-with-blog'] = {
        c: () => require('partner/themes/fabrikam-with-blog/fabrikam-with-blog.tsx'),
        $type: 'themeModule',
        da: [],
        definitionExtensions: ['buybox','content-block','media-gallery','navigation-menu','promo-banner','text-block'],
        iNM: false,
        ns: '__local__',
        n: 'fabrikam-with-blog',
        p: '__local__',
        
        pdp: '',
        
        themeSettings: 'fabrikam-with-blog.theme.settings.json',
        md: 'src/themes/fabrikam-with-blog'
    });
        

        
    window.__bindings__ = window.__bindings__ || {};
    window.__bindings__.modules = {
        ...window.__bindings__.modules || {},
        ...binding.modules
    };
    
        window.__bindings__.dataActions = {
        ...window.__bindings__.dataActions || {},
        ...binding.dataActions
    };